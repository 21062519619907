import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lying Med Ball Throw 4×6 (20/14)`}</p>
    <p>{`Shoulder Press 4×6\\@60% 1RM `}<em parentName="p">{`(speed on ascent)`}</em></p>
    <p>{`Banded Straight Arm Pulldown 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`500M Row`}</p>
    <p>{`50-Pushups`}</p>
    <p>{`25-GHD Situps (or V Ups)`}</p>
    <p>{`400M Row`}</p>
    <p>{`40-Pushups`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`300M Row`}</p>
    <p>{`30-Pushups`}</p>
    <p>{`15-GHD Situps.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`This Saturday we’ll be having a free class at 9:00 & 10:15am!  To
sign up please email us at: Daniel\\@crossfittheville.org`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      